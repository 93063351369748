.slicknav_nav ul{
    margin-left: 1rem!important;
}
.slicknav_nav>ul{
    margin-left: 0!important;
}

.hero__item {
    overflow: hidden;
    padding: 0 75px!important;
}

*:lang(id) [lang="en"] {
    display: none!important;
}
*:lang(en) [lang="id"] {
    display: none!important;
}