.hero__search__form {
    width: 75%;
}

@media only screen and (max-width: 767px) {
    .hero__search__form {
        width: 100%;
    }

    .hero__search__phone {
        display: none;
    }
}

@media only screen and (max-width: 1024px) {
    .swiper__button__product.swiper__prev {
        left: 20px;
    }

    .swiper__button__product.swiper__next {
        right: 20px;
    }
}

.product__details__tab__desc {

    ol,
    ul {
        margin-left: 1em;
    }
}

.content {
    padding: 7rem 0;
    margin: auto;
}

@media (max-width: 991.98px) {
    .content .bg {
        height: 500px;
    }
}

.content .contents,
.content .bg {
    width: 50%;
}

@media (max-width: 1199.98px) {

    .content .contents,
    .content .bg {
        width: 100%;
    }
}

.content .contents .form-group,
.content .bg .form-group {
    overflow: hidden;
    margin-bottom: 0;
    padding: 15px 15px;
    border-bottom: none;
    position: relative;
    background: #edf2f5;
    border-bottom: 1px solid #e6edf1;
}

.content .contents .form-group label,
.content .bg .form-group label {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

.content .contents .form-group input,
.content .bg .form-group input {
    background: transparent;
}

.content .contents .form-group.first,
.content .bg .form-group.first {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.content .contents .form-group.last,
.content .bg .form-group.last {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.content .contents .form-group label,
.content .bg .form-group label {
    font-size: 12px;
    display: block;
    margin-bottom: 0;
    color: #b3b3b3;
}

.content .contents .form-group.focus,
.content .bg .form-group.focus {
    background: #fff;
}

.content .contents .form-group.field--not-empty label,
.content .bg .form-group.field--not-empty label {
    margin-top: -20px;
}

.content .contents .form-control,
.content .bg .form-control {
    border: none;
    padding: 0;
    font-size: 20px;
    border-radius: 0;
}

.content .contents .form-control:active,
.content .contents .form-control:focus,
.content .bg .form-control:active,
.content .bg .form-control:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.content .bg {
    background-size: cover;
    background-position: center;
}

.content a {
    color: #888;
    text-decoration: underline;
}

.content .btn {
    height: 54px;
    padding-left: 30px;
    padding-right: 30px;
}

.content .forgot-pass {
    position: relative;
    top: 2px;
    font-size: 14px;
}

.social-login a {
    text-decoration: none;
    position: relative;
    text-align: center;
    color: #fff;
    margin-bottom: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: inline-block;
}

.social-login a span {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.social-login a:hover {
    color: #fff;
}

.social-login a.facebook {
    background: #3b5998;
}

.social-login a.facebook:hover {
    background: #344e86;
}

.social-login a.twitter {
    background: #1da1f2;
}

.social-login a.twitter:hover {
    background: #0d95e8;
}

.social-login a.google {
    background: #ea4335;
}

.social-login a.google:hover {
    background: #e82e1e;
}

.control {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 14px;
}

.control .caption {
    position: relative;
    top: .2rem;
    color: #888;
}

.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.control__indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background: #e6e6e6;
    border-radius: 4px;
}

.control--radio .control__indicator {
    border-radius: 50%;
}

.control:hover input~.control__indicator,
.control input:focus~.control__indicator {
    background: #ccc;
}

.control input:checked~.control__indicator {
    background: #6c63ff;
}

.control:hover input:not([disabled]):checked~.control__indicator,
.control input:checked:focus~.control__indicator {
    background: #847dff;
}

.control input:disabled~.control__indicator {
    background: #e6e6e6;
    opacity: 0.9;
    pointer-events: none;
}

.control__indicator svg {
    // font-family: 'icomoon';
    // content: '\e5ca';
    position: absolute;
    display: none;
    font-size: 16px;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

.control input:checked~.control__indicator svg {
    display: block;
    color: #fff;
}

.control--checkbox .control__indicator svg {
    top: 50%;
    left: 50%;
    margin-top: -1px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.control--checkbox input:disabled~.control__indicator svg {
    border-color: #7b7b7b;
}

.control--checkbox input:disabled:checked~.control__indicator {
    background-color: #7e0cf5;
    opacity: .2;
}

.shoping__cart__item img{
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: center;
}

#root{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}