.swiper__button__product{
    font-size: 18px;
    color: #1c1c1c;
    height: 70px;
    width: 30px;
    line-height: 70px;
    text-align: center;
    border: 1px solid #ebebeb;
    position: absolute;
    left: -35px;
    top: 50%;
    -webkit-transform: translateY(-35px);
    background: #ffffff;
    z-index: 10;
    cursor: pointer;
}

.swiper__button__product.swiper__next{
    left: auto;
    right: -35px;
}

.swiper-slide.swiper-slide-visible {
    opacity: 0.7;
}
.swiper-slide.swiper-slide-visible.swiper-slide-thumb-active {
    opacity: 1;
}